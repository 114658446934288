<template>
  <div class="home">
    <navbar />

    <swriper />

    <div class="jishu-tips" id="jishu-tips">
      <div>Service advantage</div>
      <div>服务优势</div>
    </div>
    <div class="jishuyoushi">
      <div class="jishuyoushi-item">
        <div class="rad item1">
          <!-- <b-icon
            icon="gear-fill"
            font-scale="3"
            class="rad-x rad-color"
          ></b-icon> -->
        </div>
        <div>
          <div>检测标准</div>
          <div>Check standard</div>
          <div>着重安全、适应行业</div>
        </div>
      </div>

      <div class="jishuyoushi-item">
        <div class="rad item2">
          <!-- <b-icon
            icon="gear-fill"
            font-scale="3"
            class="rad-x rad-color"
          ></b-icon> -->
        </div>
        <div>
          <div>检测团队</div>
          <div>Check team</div>
          <div>五年从业、两年实践</div>
        </div>
      </div>

      <div class="jishuyoushi-item">
        <div class="rad item3">
          <!-- <b-icon
            icon="gear-fill"
            font-scale="3"
            class="rad-x rad-color"
          ></b-icon> -->
        </div>
        <div>
          <div>检测定制</div>
          <div>Check customization</div>
          <div>车商品牌打造、个人车况了解</div>

        </div>
      </div>

      <div class="jishuyoushi-item">
        <div class="rad item4">
          <!-- <b-icon
            icon="gear-fill"
            font-scale="3"
            class="rad-x rad-color"
          ></b-icon> -->
        </div>
        <div>
          <div>检测实惠</div>
          <div>Check benefits</div>
          <div>定价行业最低水平</div>

        </div>
      </div>
    </div>

    <!-- 产品中心 -->

    <div class="PRODUCT" id="PRODUCT">
      <div class="indextitle">
        <div>Inspection Center</div>
        <div>检测中心</div>
      </div>

      <div class="xian"></div>

      <div class="">
        <div class="PRODUCT-content">
          <div class="item" v-for="(item, index) in 4" :key="index">
            <div class="item-img">
            

              <img v-if="index == 0" src="~@/assets/2.jpg" alt="" srcset="" />

              <img v-if="index == 1" src="~@/assets/3.jpg" alt="" srcset="" />
                <img v-if="index == 2" src="~@/assets/1.jpg" alt="" srcset="" />

              <img v-if="index == 3" src="~@/assets/4.jpg" alt="" srcset="" />
            </div>

            <div>检测中心实拍</div>

            <div class="item-line"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="jianjie" id="jianjie1">
      <div class="jianjie-title">
        <div>ABOUT</div>
        <div>悠享简介</div>
      </div>

      <div class="jianjie-conent">
        <div class="jianjie-conent-text">
          青岛悠享机动车鉴定评估有限公司是一家专业独立的第三方车况检测、鉴定机构。凭借二手车检测行业多年经验沉淀，针对二手车行业痛点，不断迭代检测服务产品及服务保障。悠享验车检测设备科学精密、检测工程师团队专业敬业、检测系统成熟完善，在不参与车辆交易的前提下，完全客观中立的提供第三方检测服务。悠享验车为二手车交易市场、平台，提供认证管理服务，为车商打造诚信商家品牌；让车商卖车放心，消费者买车安心。
        </div>

        <div class="jianjie-conent-img">
          <img
            class="jianjie-conent-img-img"
            src="~@/assets/90.png"
            alt=""
            srcset=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import swriper from "@/components/swriper.vue";
import navbar from "@/components/navbar.vue";

export default {
  name: "Home",
  components: {
    swriper,
    navbar,
  },
};
</script>
<style lang="less">
.jishu-tips {
  text-align: center;
  color: #383938;
  margin: 50px 0 35px 0;
  :first-child {
    font-size: 30px;
  }
  :last-child {
    font-size: 24px;
  }
}
.jishuyoushi {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .jishuyoushi-item {
    transition: all ease 0.3s;

    text-align: center;
    width: 215px;
    border: 1px solid #ccc;
    height: 315px;
    margin: 20px;

    .rad {
      margin: 60px auto;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      // background-color: #ccc;
      border: 1px solid rgb(0, 118, 69);
      text-align: center;
      line-height: 100px;
      color: #fff !important;
    }

    .rad-x {
      transform: translateY(25%);
    }
  }
}

.PRODUCT {
  background-color: #2c3035;
  width: 100%;
  max-width: 100%;
  .indextitle {
    text-align: center;
    color: #fff;
    padding: 50px 0 35px 0;
    font-size: 30px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background: #26292e;
  }

  .PRODUCT-content {
    width: 1100px;
    margin: 50px auto;
    height: 290px;
    display: flex;
    justify-content: space-between;
    color: #fff;
    text-align: center;
    font-size: 14px;
    line-height: 30px;
    .item {
      // float: left;
      margin-right: 22px;
      flex-shrink: 0;
      width: 250px;
      height: 287px;
      .item-img {
        img {
          width: 100%;
          height: 225px;
        }
      }

      .item-line {
        width: 100%;
        height: 2px;
        background-color: #ccc;
        margin-top: 10px;
      }
    }

    .item:hover .item-line {
      background-color: red;
    }
  }
}

.jianjie-conent-img-img {
  width: 400px;
}
.jianjie {
  margin: 40px auto;
  width: 1000px;
  .jianjie-title {
    font-size: 24px;
    color: #484848;
    text-align: center;
    width: 100%;
  }

  .jianjie-conent {
    margin-top: 30px;

    .jianjie-conent-text {
      margin-right: 20px;
      font-size: 14px;
      color: #606060;
      text-indent: 2em;
      line-height: 24px;
    }
  }
}
.clearfix:after {
  /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  *zoom: 1; /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}

@media screen and (min-width: 880px) {
  .jishuyoushi-item:hover {
    margin-top: -5px;
    // .rad {
    //   background-color: red;
    // }
  }

  .jianjie-conent {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 570px) {
  .jianjie {
    width: 90%;
    .jianjie-conent-text {
      margin-right: 1px !important;
    }
    .jianjie-conent-img {
      display: none;
    }
  }

  .jishuyoushi-item {
    width: 47% !important;
    height: 200px !important;
    margin: 1% !important;
  }
  .rad {
    margin: 10px auto !important;
  }

  .PRODUCT {
    .PRODUCT-content {
      width: 300px;
      overflow-x: auto;

      .item {
        margin-right: 22px;

        width: 250px;
        height: 287px;
      }
    }
  }
}

.item1 {
  background: url("~@/assets/images/bz_01.png") no-repeat center center;
}

.jishuyoushi-item:hover .item1 {
  background: url("~@/assets/images/bz_02.png") no-repeat center center;
  background-color: rgb(0, 118, 69);
}



.item2 {
  background: url("~@/assets/images/td_01.png") no-repeat center center;
}

.jishuyoushi-item:hover .item2 {
  background: url("~@/assets/images/td_02.png") no-repeat center center;
  background-color: rgb(0, 118, 69);
}



.item3 {
  background: url("~@/assets/images/dz_01.png") no-repeat center center;
}

.jishuyoushi-item:hover .item3 {
  background: url("~@/assets/images/dz_02.png") no-repeat center center;
  background-color: rgb(0, 118, 69);
}



.item4 {
  background: url("~@/assets/images/yy_01.png") no-repeat center center;
}

.jishuyoushi-item:hover .item4 {
  background: url("~@/assets/images/yy_02.png") no-repeat center center;
  background-color: rgb(0, 118, 69);
}
</style>
