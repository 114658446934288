<template>
  <div class="footers" id="footers">
    <div class="content">
      <div class="content-item s1">
        <div class="tilte">备案号</div>

        <div class="co">
          <div>
            <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备2021003243号</a>
          </div>
        </div>
      </div>

      <div class="content-item s1">
        <!-- <div class="tilte">关于我们</div>
        <div class="link">
          <span class="link-s" @click="toJianjie">公司简介</span>
        </div> -->
        <div class="tilte">公众号</div>
        <div class="gongzhonghao martop">
          <div>
            <img src="@/assets/gognzhonghao.jpg" alt="" srcset="" />
          </div>
        </div>
      </div>

      <div class="content-item s1">
        <div class="tilte">联系我们</div>

        <div class="co">
          <div class="co-s">400-000-2080</div>
          <div class="co-s">0537-2251669</div>
          <div>
            <span
              >公司地址：山东省济宁市高新区大禹西路臻品二手车交易市场内(申科汽车广场西邻)</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toJianjie() {
      document.querySelector("#jianjie1").scrollIntoView(true);
    },
  },
};
</script>
<style lang="less">
.footers {
  width: 100%;
  min-height: 382px;
  background: #2c3035;
  padding-top: 100px;

  .content {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .content-item {
      width: 145px;
      .tilte {
        height: 35px;
        text-transform: uppercase;
        width: 100%;
        border-bottom: #212427 solid 1px;
        line-height: 35px;
        font-size: 14px;
        color: #ffffff;
        font-weight: bolder;
      }

      .link {
        display: inline-block;
        width: 100%;
        font-size: 12px;
        color: #74787c;
        line-height: 32px;
      }

      .co {
        color: #fff;
        .co-s {
          font-size: 24px;
        }
      }
    }
  }
}
a {
  color: #74787c;
}
.link-s {
  cursor: pointer;
}
.s1 {
  width: 286px !important;
  font-size: 12px;
}
@media screen and (max-width: 580px) {
  // .left1 {
  //   display: none;
  // }

  .footers {
    .content {
      width: 100% !important;
      flex-direction: column;
      .s1 {
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  .gongzhonghao {
    // width: 80%;
    // height: 80%;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.gongzhonghao {
  width: 100px;
  height: 100px;

  img {
    width: 100px;
    height: 100px;
  }
}

.martop {
  margin-top: 10px;
}
</style>