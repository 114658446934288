<template>
  <div class="nav">
    <div class="logo">
      <img src="@/assets/logo.png" alt="" srcset="" />
    </div>
    <div class="nav-content">
      <div class="nav-item">首页</div>
      <div class="nav-item" @click="toId('#jianjie1')">关于我们</div>

      <div class="nav-item" @click="toId('#PRODUCT')">检测中心</div>

      <div class="nav-item" @click="toId('#jishu-tips')">技术优势</div>
      <div @click="toId('#footers')" class="nav-item">联系我们</div>
    </div>

    <!-- <div class="logo ss1">
      <img src="@/assets/gognzhonghao.jpg" alt="" srcset="">
    </div> -->

    <div class="logo ss1">
      <div>
        <img src="@/assets/kefu.png" alt="" srcset="" />
        <div class="s1333">微信客服</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    toId(id) {
      document.querySelector(id).scrollIntoView(true);
    },
  },
};
</script>
<style lang="less">
.s1333 {
  text-align: center;
  font-size: 14px;
}
.nav {
  width: 1000px;
  margin: 0 auto;
  height: 80px;

  color: black;

  .nav-item {
    display: inline-block;
    padding: 0 20px;
    line-height: 80px;
    cursor: pointer;
  }

  .logo {
    width: 60px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
    }
    div {
      img {
      width: 60px;
      height: 60px;
    }
    }
  }

  .ss1 {
    width: 80px;
    margin-right: 20px;
    img {
      width: 80px;
      height: 80px;
    }
  }
}

.nav-item:hover {
  color: #ccc;
}

@media screen and (min-width: 880px) {
  .nav {
    .nav-content {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 550px) {
  .nav {
    width: 100%;
    .nav-item {
      display: inline-block;
      padding: 0 5px;
      line-height: 80px;
    }

    .nav-content {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>