<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
    <footers/>
  </div>
</template>
<script>
import footers from '@/components/footer.vue'
export default {
  data() {
    return {

    }
  },
  components: {
    footers
  }
}
</script>

<style>

</style>
